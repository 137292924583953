{
  "dirty": true,
  "raw": "v1.75.0-54-gc27fe1a8c-dirty",
  "hash": "gc27fe1a8c",
  "distance": 54,
  "tag": "v1.75.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.75.0",
    "major": 1,
    "minor": 75,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.75.0"
  },
  "suffix": "54-gc27fe1a8c-dirty",
  "semverString": "1.75.0+54.gc27fe1a8c.dirty"
}