<div class="flex gap-4">
  @if ((isSmallerThanMedium$ | async) === false) {
    <div class="sidebar-container px-4 min-w-[23%]">
      <div class="flex items-center m-4">
        <atom-icon
          iconId="atom:core:user"
          class="shrink-0 text-3xl mx-2"
        ></atom-icon>
        <p class="font-semibold userName">
          {{ $user().firstName }} {{ $user().lastName }}
        </p>
      </div>

      <ul class="navigation">
        <li routerLink="/user/profile" routerLinkActive="active">
          <div class="px-4">
            <a class="navItems" i18n>My Profile</a>
          </div>
        </li>

        @if (hasOrderHistoryPermission$ | async) {
          <li routerLink="/user/order-history" routerLinkActive="active">
            <div class="px-4">
              <a class="navItems" i18n>Order History</a>
            </div>
          </li>

          <li routerLink="/user/delivery-documents" routerLinkActive="active">
            <div class="px-4">
              <a class="navItems" i18n>Delivery Documents</a>
            </div>
          </li>
        }

        @if (hasFinancialOverviewPermission$ | async) {
          <li routerLink="/user/financial-docs" routerLinkActive="active">
            <div class="px-4">
              <a class="navItems" i18n>Financial Documents</a>
            </div>
          </li>
        }

        <li routerLink="/user/request-history" routerLinkActive="active">
          <div class="px-4">
            <a class="navItems" i18n>Request History</a>
          </div>
        </li>

        @if ((hasCheckoutPermission$ | async) && salesLogicPermitted) {
          <li routerLink="/user/saved-carts" routerLinkActive="active">
            <div class="px-4">
              <a class="navItems" i18n>Saved Carts</a>
            </div>
          </li>
        }
        <a class="logout navItems px-6" (click)="onLogoutAttempt()" i18n
          >Logout</a
        >
      </ul>
    </div>
  }

  <div class="main-container grow">
    <router-outlet></router-outlet>
  </div>
</div>
