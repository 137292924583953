/* eslint-disable @nx/enforce-module-boundaries */
import { Routes } from '@angular/router';
import { ProfileNavComponent } from '@ev-portals/cp/frontend/dashboard/feature-profile-nav';
import {
  CheckoutPermissionGuard,
  OrderHistoryPermissionGuard,
  SalesLogicPermissionGuard,
} from '@ev-portals/cp/frontend/shared/auth/util';

export const profileRoutes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ProfileNavComponent,
    children: [
      {
        path: 'profile',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/user/feature-user-profile').then(
            m => m.ProfileInfoComponent,
          ),
      },
      {
        path: 'request-history',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/request/feat-request-history').then(
            m => m.RequestHistoryComponent,
          ),
      },
      {
        path: 'request-history/:id',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/request/feat-request-details').then(
            m => m.RequestDetailsComponent,
          ),
      },
      {
        path: 'order-history',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/order/feat-order-history').then(
            m => m.OrderHistoryComponent,
          ),
        canActivate: [OrderHistoryPermissionGuard],
      },
      {
        path: 'order-history/:id',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/order/feat-order-details').then(
            m => m.OrderDetailsComponent,
          ),
        canActivate: [OrderHistoryPermissionGuard],
      },
      {
        path: 'delivery-documents',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/order/feat-delivery-document-search').then(
            m => m.DeliveryDocumentSearchComponent,
          ),
        canActivate: [OrderHistoryPermissionGuard],
      },
      {
        path: 'financial-docs',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/order/feat-financial-docs').then(
            m => m.FinancialDocsComponent,
          ),
      },
      {
        path: 'saved-carts',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/purchase/feat-saved-carts').then(
            m => m.SavedCartsComponent,
          ),
        canActivate: [CheckoutPermissionGuard, SalesLogicPermissionGuard],
      },
      {
        path: 'saved-carts/:id',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/purchase/feat-saved-cart-details').then(
            m => m.SavedCartDetailsComponent,
          ),
        canActivate: [CheckoutPermissionGuard, SalesLogicPermissionGuard],
      },
    ],
  },
];
