export const federationBaseUrl = 'https://federation.basf.com';
export const federationBaseUrlQa = 'https://federation-qa.basf.com';

export const mpcBaseUrl = 'https://myperformancechemicals.basf.com';
export const mpcBaseUrlPreProd = 'https://preprod.myperformancechemicals.basf.com';
export const mpcBaseUrlDev = 'https://dev.myperformancechemicals.basf.com';
export const mpcBaseUrlQa = 'https://qa.myperformancechemicals.basf.com';

export const myBasfWorldStoreBaseUrlDev = 'https://my-basf-world.qa.dcp-storefront.basf.com';
export const myBasfWorldStoreBaseUrlQa = 'https://my-basf-world.qa.dcp-storefront.basf.com';
export const myBasfWorldStoreBaseUrlPreprod = 'https://my-basf-world.qa.dcp-storefront.basf.com';
export const myBasfWorldStoreBaseUrl = 'https://my-basf-world.qa.dcp-storefront.basf.com';
