import { Environment } from '@ev-portals/cp/frontend/shared/util';

import projectJson from '../../project.json';
import { versionInfo } from '../version-info';
import { federationBaseUrlQa, myBasfWorldStoreBaseUrlDev } from './base-urls';

const defaultLocale = projectJson.i18n.sourceLocale.code;
export const locales = [defaultLocale, ...Object.keys(projectJson.i18n.locales)];

export const localEnvironment: Environment = {
  production: false,
  version: versionInfo.tag,
  // remove the leading 'g' character, which is coming from git-describe npm package
  hash: versionInfo.hash.substring(1),
  environment: 'local',
  apiMockData: false,
  authClientId: '21aa160a-4891-4046-afd2-072f2a6590ad',
  baseUrl: 'http://localhost:4200',
  apiBaseUrl: 'http://localhost:3000',
  myBasfStoreBaseUrl: myBasfWorldStoreBaseUrlDev,
  loginPath: `${federationBaseUrlQa}/nidp/oauth/nam/authz`,
  logoutPath: `${federationBaseUrlQa}/nidp/app/logout`,
  appInsights: {
    instrumentationKey: 'dc465359-7d1d-4dcc-833a-590a995f5d60',
  },
  locales,
  footerLinks: {
    automotiveTransportation: '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a61d61347',
    miningSolutions: '',
    nutrition: '',
    oilfieldChemicals:
      '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a6405139b/8a8081ad85ed417f0185ed96375444db',
    plasticAdditives:
      '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a606512f5/8a8081ad85ed417f0185ed9631c044c5/8a8082f885ed417b0185edbf0b601cc6',
  },
};
