import '@angular/localize/init';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { getApiServiceProviders } from '@ev-portals/cp/frontend/shared/api-client';
import { AuthInterceptor } from '@ev-portals/cp/frontend/shared/auth/util';
import { Environment } from '@ev-portals/cp/frontend/shared/util';
import { loadBasfAtoms } from '@ev-portals/ev/frontend/ui-library';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { ScriptInjector } from './app/util';
import { environment } from './environments/environment';

init();

async function init(): Promise<void> {
  ScriptInjector.injectTealiumScript();
  ScriptInjector.injectNpsScript();
  ScriptInjector.injectNoIndexMetaTag();

  // Going to be loaded asynchronously, to reduce the size of the main.js
  await loadBasfAtoms();

  if (environment.production) {
    enableProdMode();
  }

  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(BrowserAnimationsModule),
      importProvidersFrom(HttpClientModule),
      provideRouter(
        appRoutes,
        withComponentInputBinding(),
        withPreloading(PreloadAllModules),
        // Note: doesn't work due to atom-shell, we fix this in NavigationService
        withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      ),
      {
        provide: LOCALE_ID,
        useFactory: () =>
          environment.locales.find(locale => window.location.href.includes(locale)) || 'en-GB',
      },
      { provide: Environment, useValue: environment },
      ...getApiServiceProviders({
        useMockData: environment.apiMockData,
        apiBaseUrl: environment.apiBaseUrl,
      }),
      [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    ],
  }).catch(console.error);

  // import { ApplicationInsights } from '@microsoft/applicationinsights-web';
  // Lazy load the Application Insights SDK to optimize initial package size
  import('@microsoft/applicationinsights-web').then(m => {
    // Configure Azure Application Insights
    const appInsights = new m.ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        /* ...Other Configuration Options... */
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  });
}
